<script setup>
import pageTitle, { setPageTitle } from '@/utils/pageTitle';
import BinForm from '@/components/bins/BinForm.vue';
import { onMounted } from 'vue';
import useBins from '@/hooks/useBins';

const { addBin } = useBins()

onMounted(() => {
    setPageTitle("New Bin/Location")
})

const save = async ( binData ) => {
    await addBin(binData)
}

</script>

<template>
    <v-card-title>{{pageTitle}}</v-card-title>
    <BinForm btnTitle="save" @binActionHandler="save"/>
</template>